export default (emit, validations = null) => {
    /*
        States
    */
    const hasFocus = ref(false);
    const inputValue = ref(null);
    const errors = reactive([]);

    /*
        Methods
    */
    const setFocus = (value) => {
        hasFocus.value = value;
    };

    const setValue = (event) => {
        inputValue.value = event?.target?.value || event;
    };

    /*
        Computed
    */
    const hasContent = computed(() => inputValue.value && inputValue.value.toString().length > 0);

    /*
        Form Field Validation
    */
    if (validations) {
        watch(() => hasFocus.value, (newValue) => {
            if (newValue === false) {
                errors.length = 0;

                const { validate } = useFormValidation();
                Object.entries(validations).forEach((e) => {
                    const res = validate(e, inputValue.value);
                    if (res === false) {
                        errors.push({
                            validator: e[0],
                            validatedValue: e[1].value,
                            message: e[1].message,
                        });
                    }
                });
            }
        });

        /* Reset error on typing */
        watch(() => inputValue.value, () => {
            errors.length = 0;
        });
    }

    /*
        Prevent submit on errors
    */
    watch(() => errors.length, (newValue) => {
        if (newValue > 0) {
            emit('hasErrors', true);
        } else {
            emit('hasErrors', false);
        }
    });

    /*
        Emitting
    */
    const handleEmit = (event, key) => {
        const obj = {
            key,
            value: event.target?.value || event,
        };

        emit('emitValue', obj);
    };

    return {
        hasFocus,
        errors,
        hasContent,
        inputValue,
        handleEmit,
        setValue,
        setFocus,
    };
};
