const _DEBUGGER_ = false;

export default () => {
    const validators = {
        /*
            @title: isRequired
            @usageexample:
            isRequired: {
                value: true,
                message: 'your message'
            }
            @description: Validates if a value is set or not
        */
        _isRequired: (vValue) => (!!vValue),

        /*
            @title: isTrue
            @usageexample:
            isTrue: {
                value: true,
                message: 'your message'
            }
            @description: Checks if a boolean is true
        */
        _isTrue: (vValue) => vValue === true,

        /*
            @title: isFalse
            @usageexample:
            isFalse: {
                value: true,
                message: 'your message'
            }
            @description: Checks if a boolean is false
        */
        _isFalse: (vValue) => vValue === false,

        /*
            @title: isNot
            @usageexample:
            isNot: {
                value: true,
                message: 'your message'
            }
            @description: Checks if a value is not the test value
        */
        _isNot: (vValue, testValue) => vValue !== testValue,

        /*
            @title: isSame
            @usageexample:
            isSame: {
                value: true,
                message: 'your message'
            }
            @description: Checks if a value is same as the test value
        */
        _isSame: (vValue, testValue) => vValue === testValue,

        /*
            @title: isNumber
            @usageexample:
            isNumber: {
                value: true,
                message: 'your message'
            }
            @description: Validates if a given Values is a number
        */
        _isNumber: (vValue) => typeof vValue === 'number',

        /*
            @title: isDate
            @usageexample:
            isDate: {
                value: true,
                message: 'your message'
            }
            @description: Validates if a given Values is a valid date
        */
        _isDate: (vValue) => vValue.toString() !== 'Invalid Date',

        /*
            @title: isValidDateFormat
            @usageexample:
            isValidDateFormat: {
                value: true,
                message: 'your message'
            }
            @description: Validates if a given value matches the date format DD/MM/YYYY
        */
        _isValidDateFormat: (vValue) => {
            const regex = /^\d{4}-\d{2}-\d{2}$/;
            const match = vValue.match(regex);
            return !!match;
        },

        /*
            @title: isEmail
            @usageexample:
            isEmail: {
                value: true,
                message: 'your message'
            }
            @description: Validates if a given value is a email adress
        */
        _isEmail: (vValue) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(vValue),

        /*
            @title: isInteger
            @usageexample:
            isInteger: {
                value: true,
                message: 'your message'
            }
            @description: Validates if a given Number is an integer
        */
        _isInteger: (vValue) => {
            if (validators._isRequired(vValue) === false) return false;
            return Number.isInteger(vValue);
        },

        /*
            @title: isString
            @usageexample:
            isString: {
                value: true,
                message: 'your message'
            }
            @description: Validates if a given Values is a string
        */
        _isString: (vValue) => typeof vValue === 'string',

        /*
            @title: isArray
            @usageexample:
            isArray: {
                value: true,
                message: 'your message'
            }
            @description: Validates if a given Values is an array
        */
        _isArray: (vValue) => vValue.isArray(),

        /*
            @title: minLength
            @usageexample: {
                minLength: 3,
                message: 'your message'
            }
            @description: Validates if a given Values length
            is greater or equal than the given minimum value
        */
        _minLength: (vValue, min) => {
            if (validators._isRequired(vValue) === false) return false;

            const res = vValue.length >= min;
            return res;
        },

        /*
            @title: maxLength
            @usageexample: {
                maxLength: 3,
                message: 'your message'
            }
            @description: Validates if a given Values length
            is smaller or equal than the given minimum value
        */
        _maxLength: (vValue, max) => vValue?.length <= max,

        /*
            @title: minValue
            @usageexample: {
                minValue: 3,
                message: 'your message'
            }
            @description: Validates if a given value is a number and at a given minimum
        */
        _minValue: (vValue, min) => {
            const value = parseInt(vValue, 10);
            return value >= min;
        },

        /*
            @title: maxValue
            @usageexample: {
                maxValue: 3,
                message: 'your message'
            }
            @description: Validates if a given value is a number and at a given minimum
        */
        _maxValue: (vValue, max) => {
            const value = parseInt(vValue, 10);
            return value <= max;
        },

        /*
            @title: betweenValues
            @usageexample: {
                betweenValues: [10, 20]
            }
            @description: Validates if a given value is between two numbers
        */
        _betweenValues: (vValue, minMax) => {
            if (validators._isRequired(vValue) === false) return false;
            if (validators._isNumber(vValue) === false) return false;

            return vValue >= minMax[0] && vValue.value <= minMax[1];
        },

        /*
            @title: minDate
            @usageexample: {
                minDate: {{datestring}},
                message: 'your message'
            }
            @description: Validates if a given date is greater or equal than a compare date
        */
        _minDate: (vValue, min) => {
            const dateToTest = new Date(min);
            const dateToCompare = new Date(vValue);

            return dateToCompare >= dateToTest;
        },

        /*
            @title: maxDate
            @usageexample: {
                maxDate: {{datestring}},
                message: 'your message'
            }
            @description: Validates if a given date is lower or equal than a compare date
        */
        _maxDate: (vValue, max) => {
            const dateToTest = new Date(max);
            const dateToCompare = new Date(vValue);

            return dateToCompare <= dateToTest;
        },

        /*
            @title: betweenDates
            @usageexample: {
                betweenDates: [{{your date string 1}}, {{your date string 2}}],
                message: 'your message'
            }
            @description: Validates if a given date is greater than a compare date
        */
        _betweenDates: (vValue, minMax) => {
            const lowestDate = new Date(minMax[0]);
            const greatestDate = new Date(minMax[1]);
            const dateToCompare = new Date(vValue);

            return dateToCompare >= lowestDate && dateToCompare <= greatestDate;
        },

        /*
            @title: isUrl
            @usageexample:
            isUrl: {
                value: true,
                message: 'your message'
            }
            @description: Validates if a value is a url
        */
        _isUrl: (vValue) => /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/.test(vValue),

        /*
            @title: isAlphabetic
            isAlphabetic: {
                value: true,
                message: 'your message'
            }
            @description: Validates if a only contains alphaetic chars without numbers
            and special chars
        */
        _isAlphabetic: (vValue) => /[A-Za-zÀ-ÖØ-öø-ÿ ]/.test(vValue),

        /*
            @title: isAlphaNumeric
            isAlphaNumeric: {
                value: true,
                message: 'your message'
            }
            @description: Validates if a only contains alphaetic chars and numbers without
            special chars
        */
        _isAlphaNumeric: (vValue) => /^[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]+$/.test(vValue),

        /*
            @title: isNumeric
            isNumeric: {
                value: true,
                message: 'your message'
            }
            @description: Validates if a string contains only numbers
        */
        _isNumeric: (vValue) => /^\d+$/.test(vValue),

        /*
            @title: customValidation
            customValidation: {
                value: () => {
                    console.log('custom callback for validation')
                },
                message: 'your message'
            }
            @description: Validates if a given value is a email adress
        */
        _customValidation: (vValue) => vValue(),

        /*
            @title: sameAs
            sameAs: {
                value: 'some-element-id-in-that-form',
                message: 'your message'
            }
            @description: Validates if a value matches the value of an other field
        */
        _sameAs: (vValue, fieldId) => { // Storyblok
            const element = document.querySelector(`#${fieldId}`);
            return validators._isSame(vValue, element.value);
        },
        /*
            @title: notSameAs
            notSameAs: {
                value: 'some-element-id-in-that-form',
                message: 'your message'
            }
            @description: Validates if a value matches the value of an other field
        */
        _notSameAs: (vValue, fieldId) => { // Storyblok
            const element = document.querySelector(`#${fieldId}`);
            return validators._isNot(vValue, element.value);
        },
    };

    /*
        Validation Function
        @param validation: the key of the validator that gets called. e.g isSame
        @param vValue: the comparisation value or values if its between
    */
    const validate = (validation, vValue) => {
        const [vFunction, vArgument] = validation;

        /* Call validator based on validation string */
        const res = validators[`_${vFunction}`](vValue, vArgument.value);

        if (_DEBUGGER_) {
            // eslint-disable-next-line no-console
            console.log(res);
        }

        return res;
    };

    /*
        Build Validation Object from storyblok data
        @param rawData: Mostly data.props
    */
    const buildValidationObject = (rawData) => {
        const checkRequired = () => rawData.is_required;
        const isRequired = checkRequired();
        const validations = {};

        /* Add required validator if is_required checkbox in storyblok is checked */
        if (isRequired) {
            validations.isRequired = {
                value: true,
                message: rawData.required_error_message,
            };
        }

        if (!rawData.validations) return validations;

        /* an array of keys to be able to know which of them needs to be parsed to an integer */
        const needsIntegerParsing = [
            'minValue',
            'maxValue',
        ];

        /* Helper function to build the validation object */
        const prepareComparisationValue = (validationType, comparisationValue) => {
            if (needsIntegerParsing.includes(validationType)) {
                return parseInt(comparisationValue, 10);
            }
            if (validationType.includes('is')) return true;
            return comparisationValue;
        };

        /* Map the validation data of storyblok into an array for the validator */
        const mappedValidations = [...rawData.validations].map((
            {
                validation_type: validationType,
                validation_message: validationMessage,
                comparisation_value: comparisationValue,
            },
        ) => (
            {
                [validationType]: {
                    value: prepareComparisationValue(validationType, comparisationValue),
                    message: validationMessage,
                },
            }));

        /* Reduce the mapped validation array to an object */
        Object.assign(validations, ...mappedValidations);

        if (Object.keys(validations).length === 0) return null;

        return validations;
    };

    return {
        validate,
        buildValidationObject,
    };
};
